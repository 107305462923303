import { graphql, Link } from 'gatsby';
import React from 'react';
import Seo from '../components/base/Seo';
import FullPageFrom from '../components/organisms/FullPageForm';

const LoginPage = ({ data: { data } }) => {
  return (
    <>
      <Seo post={data} />

      <FullPageFrom>
        <h1 className="mb-5 u-h4">Log in</h1>
        <p className="font-semibold">
          Don’t have an account?{' '}
          <Link
            className="font-normal underline transition hover:text-primary-red focus:text-primary-red focus:outline-none"
            to="/get-started"
          >
            Get Started
          </Link>
        </p>
        <form className="pt-6 space-y-4 pb-9">
          <div>
            <input
              name="email"
              type="email"
              placeholder="Email Address"
              className="u-form-input"
            />
          </div>
          <div>
            <input
              name="password"
              type="password"
              placeholder="Password"
              className="u-form-input"
            />
          </div>

          <button className="u-form-button">Log in</button>
        </form>

        <Link
          to="/forgotten-password"
          className="text-sm underline transition text-body-copy opacity-60 hover:opacity-100 focus:opacity-100 focus:outline-none"
        >
          Forgot your password?
        </Link>
      </FullPageFrom>
    </>
  );
};

export const pageQuery = graphql`
  query LogInPageQuery {
    data: wpPage(slug: { eq: "log-in" }) {
      title
      ...SEO
    }
  }
`;

export default LoginPage;
