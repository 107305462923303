import { Link } from 'gatsby';
import React from 'react';
import Div100vh from 'react-div-100vh';
import Logo from '../atoms/Logo';
import LogoIcon from '../atoms/LogoIcon';

const FullPageFrom: React.FC = ({ children }) => (
  <Div100vh className="md:flex">
    <div className="flex flex-col w-full px-16 py-16 md:w-120 lg:w-152 lg:px-28 md:py-28">
      <div className="mb-16">
        <Link to="/">
          <Logo className="w-auto h-9" />
        </Link>
      </div>

      <div className="flex items-center justify-center flex-grow w-full mb-24">
        <div className="w-full">{children}</div>
      </div>
    </div>
    <div
      className="relative items-center justify-center flex-grow hidden bg-cover md:flex"
      style={{
        backgroundImage:
          'linear-gradient(159.3deg, #F7E69A -48.7%, #EE7272 80.57%)',
      }}
    >
      <img
        src="/images/logo-icon.png"
        alt="Spirable Logo"
        className="w-16 h-auto"
      />

      <img
        src="/images/ribbon-top-left.png"
        alt=""
        className="absolute top-0 left-0 w-auto h-152"
      />

      <img
        src="/images/ribbon-bottom-right.png"
        alt=""
        className="absolute bottom-0 right-0 w-auto h-120"
      />

      <img
        src="/images/ribbon-bottom-left.png"
        alt=""
        className="absolute bottom-0 left-0 h-auto w-120"
      />

      <Link
        to="/"
        className="absolute flex items-center justify-center w-12 h-12 bg-black rounded-full top-8 right-8 bg-opacity-15"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-2.25 h-2.25"
        >
          <path
            d="M10.3203 1.5L1.32031 10.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
          <path
            d="M1.32031 1.5L10.3203 10.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
        </svg>
      </Link>
    </div>
  </Div100vh>
);

export default FullPageFrom;
